<template>
   <div class="b-participants-summary" v-if="groupStructure">
      <SummaryItem icon="profile" class="b-participants-summary__participants-info" >
         <div>
            <!-- clarification title -->
            <div class="b-participants-summary__clarification-title">
               {{ $t('participant_summary_clarfication') }}
            </div>

            <!-- total participants -->
            <div class="b-summary-item__main-text">
               {{ $t("participants") }}: {{ totalPax }}
            </div>

            <!-- students info -->
            <div v-if="isClassTrip" class="b-participants-summary__type-wrapper">
               <span class="h-font-bold">
                  {{ $t("students") }}: {{ groupStructure.kidsTotal }}
               </span>
               <span class="b-summary-item__light-text">
                  ({{ groupStructure.kidsFemale }} {{ $t("female") }} /
                  {{ groupStructure.kidsMale }} {{ $t("male") }})
               </span>
            </div>

            <!-- adults info -->
            <div class="b-participants-summary__type-wrapper">
               <span class="h-font-bold">
                  {{ isClassTrip ? $t("teachers") : $t("adults") }}: {{ groupStructure.adultsTotal }}
               </span>

               <span class="b-summary-item__light-text">
                  ({{ groupStructure.adultsFemale }} {{ $t("female") }} /
                  {{ groupStructure.adultsMale }} {{ $t("male") }})
               </span>
            </div>

            <!-- kids info -->
            <div v-if="!isClassTrip" class="b-participants-summary__type-wrapper">
               <span class="h-font-bold">
                  {{ $t("kids") }}: {{ groupStructure.kidsTotal }}
               </span>
               <span class="b-summary-item__light-text">
                  ({{ groupStructure.kidsFemale }} {{ $t("female") }} /
                  {{ groupStructure.kidsMale }} {{ $t("male") }})
               </span>
            </div>

            <!-- bus drive -->
            <div v-if="groupStructure.busDrivers" class="b-participants-summary__type-wrapper">
               <span class="h-font-bold">
                  {{ $t("bus_drivers") }}: {{ groupStructure.busDrivers }}
               </span>
            </div>
         </div>
      </SummaryItem>

      <WarningMessage />
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import SummaryItem from '@/components/shared/SummaryItem';
   import WarningMessage from '@/components/participant_list/WarningMessage.vue';

   export default {
      components: { SummaryItem, WarningMessage },
      computed: {
         ...mapState([
            'totalPax',
            'isClassTrip',
            'groupStructure',
         ]),
      },
    };
</script>

<style lang="less">
   .b-participants-summary {
      .h-trip-summary;

      &__clarification-title {
         font-size: calc((13 / var(--base-value)) * 1rem);
         color: var(--color-gray-2);
      }

      .b-summary-item {
         white-space: nowrap;
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         .h-flex-col;
         .align-items(flex-start);

         .b-participants-warning-message {
            max-width: none;
            margin-left: 0;
            margin-top: 15px;
         }
      }
   }
</style>