<template>
   <div
      class="b-participants-warning-message"
      :class="{ 'is-warning' : !canEditParticipantList }"
   >
      <div v-if="canEditParticipantList">
         <span class='h-font-bold'> {{ $t('important') }}: </span><br>
         {{ topMessage }}
         <br><br>

         <span class='h-font-bold'>
            {{ $t('aditional_information') }}:
         </span>
         <br>
         {{ $t('data_deletion_schedule') }}
      </div>

      <div v-if="!canEditParticipantList"> {{ $t("participant_list_blocked_by_db") }} </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      computed: {
         ...mapState([
            'canEditParticipantList',
            'isClassTrip',
         ]),
         topMessage() {
            return this.isClassTrip
               ? this.$t('class_participant_list_top_message',
                  { email:'db-klassenfahrten@deutschebahn.com'}
                  )
               : this.$t('group_participant_list_top_message',
                  { email:'db-gruppen@deutschebahn.com'}
               );
         },
      },
   };
</script>

<style lang="less">
   .b-participants-warning-message {
        background-color: #f7e5a1;
        padding: 10px;
        border-radius: 5px;
        max-width: 550px;
        margin-left: 40px;

        &.is-warning {
           background-color: hsl(var(--color-red-hs) 90%);
        }
    }
</style>